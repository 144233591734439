import React, { Component } from 'react'
import { string, bool, number, array, func } from 'prop-types'

export const Video = ({ src }) => (
  <div className='content-holder'>
    <div className='content'>
      <div className='body'>
        <iframe
          title='cats'
          src={src}
          allow='autoplay; encrypted-media'
          allowFullScreen
        />
      </div>
    </div>
  </div>
)

Video.propTypes = {
  src: string
}

class Lightbox extends Component {
  constructor (props) {
    super()
    this.state = {
      photoIndex: props.mainSrc,
      open: false
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.open !== this.props.open) this.setState({ open: !this.state.open })
  }

  close () {
    this.setState({ open: false })
    this.props.onCloseRequest()
  }

  render () {
    if (!this.props.open) return null
    return (
      <div className='iframe-lightbox'>
        <div className='backdrop' />
        <div className='iframe-lightbox-spinner' />
        <div className='iframe-lightbox-control iframe-lightbox-close' onClick={() => this.close()} />
        <div
          className='iframe-lightbox-control iframe-lightbox-right'
          onClick={() =>
            this.setState({
              photoIndex: (this.state.photoIndex + 1) % this.props.elements.length
            })}
        />
        <div
          className='iframe-lightbox-control iframe-lightbox-left'
          onClick={() =>
            this.setState({
              photoIndex: (this.state.photoIndex + this.props.elements.length - 1) % this.props.elements.length
            })}
        />
        <div className='iframe-content'>
          <Video src={this.props.elements[this.state.photoIndex].src} />
        </div>
        <div className='iframe-strip'>
          {this.props.elements.map((entry, key) => {
            return (
              <div
                onClick={() => this.setState({ photoIndex: key })}
                key={key}
                className={`iframe-strip-item ${this.state.photoIndex === key ? 'current' : ''}`}
              >
                <div className='iframe-strip-item-thumbnail'>
                  <img src={entry.thumbnail} />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

Lightbox.propTypes = {
  elements: array,
  open: bool,
  mainSrc: number,
  onCloseRequest: func
}

export default Lightbox

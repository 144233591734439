import React from 'react'
import { A, Text, Accordion, AccordionItem, SpotIllustration } from '@rebrandly/styleguide'

import './HomeFAQBanner.css'

export default () => {
  return (
    <div className='HomeFAQ'>
      <div className='Container--wide p-b-36 p-t-36'>
        <div className='HomeFAQ__title flex-between middle-xs'>
          <Text size='x-large' className='Text--Negative'>
            <h2>Rebrandly Frequently Asked Questions</h2>
          </Text>
          <SpotIllustration className='display-none display-sm-block' name='ill-spot-sliders-white' size='sm' />
        </div>

        <div className='display-sm-none'>
          <Accordion>
            <AccordionItem
              title={<h3>What is a URL shortener?</h3>}
              text={<span>
                A URL shortener, or a link shortener, simplifies long and complicated URLs into brief, comprehensible links. The application of a URL shortener can notably elevate your digital marketing effortss. When you choose to shorten URL, you&apos;re making a complex web address more user-friendly and accessible.
                <br />
                For instance, a URL such as <A size='lg' href='https://rb.gy/BakingMasterclass'>rb.gy/BakingMasterclass</A> immediately communicates the content of the link, making it more inviting for users to click.
                <br />
                For anyone producing or circulating content online, understanding how to shorten a URL is essential.
                <br />
                Enhance your online presence with our effortless and free URL shortening tool presented above.
              </span>}
            />
            <AccordionItem
              title={<h3>What is a custom URL shortener?</h3>}
              text='A custom URL shortener, like Rebrandly, facilitates the transformation of long URLs into succinct, brand-enhancing links. Rather than a random set of letters and numbers, a custom URL shortener allows you to include identifiable words or phrases. This tool enables you to convert a standard URL into a brand-relevant, shortened URL, thereby enhancing brand visibility and recall and building trust with your audience.'
            />
            <AccordionItem
              title={<h3>How do I shorten a URL for free?</h3>}
              text='You can shorten a URL for free using online services like RB.GY. The process couldn&apos;t be simpler: access RB.GY&apos;s URL shortener tool, insert your long URL into the specified field, then click to generate a shortened URL.'
            />
            <AccordionItem
              title={<h3>How do I change a long URL to a short URL?</h3>}
              text='To shorten a URL, you&apos;ll need a service like RB.GY. First, copy the long URL you want to shorten. Then, access the URL shortener tool. Paste your long URL into the appropriate field, then click "Shorten URL." RB.GY will instantly generate a shortened version of your original URL, which leads to the same page when clicked.'
            />
            <AccordionItem
              title={<h3>Which link shortener is best?</h3>}
              text={<span>Rebrandly stands out as a top-rated URL shortener due to its dependability, security, and array of link management features. Beyond merely providing a way to shorten URL, Rebrandly also delivers performance tracking through click-through rates and the option to brand URLs. Explore Rebrandly&apos;s premium features to fully optimize your link management.</span>}
            />
            <AccordionItem
              title={<h3>What are the benefits of a short URL?</h3>}
              text={<span>
                When you choose to shorten a URL, you&apos;re optimizing your digital content in several ways. Short URLs are more manageable and tidy, fitting neatly into character-limited spaces like tweets or Instagram bios. They are also simpler to remember and type manually. Moreover, many URL shorteners include tracking capabilities, offering vital data on link engagement – a crucial tool for businesses and marketing campaigns. Finally, short URLs can be customized for increased brand recognition and user trust.
                    </span>}
            />
            <AccordionItem
              title={<h3>Does Rebrandly offers free plans?</h3>}
              text='Yes. Rebrandly offers free plans, as well as paid and custom options that vary in price based on the number of links you need to share, the number of users associated with your account, how many custom domains you use and which features are the most important to you.'
            />
            <AccordionItem
              title={<h3>Can I use a domain I already own?</h3>}
              text='Absolutely! Personalization, flexibility, and control are what Rebrandly is all about. With each of our paid plans, you can register or connect at least one top-level domain (your-domain.com) or subdomains (sub.your-domain.com) that you already own to create your branded short links using Rebrandly.'
            />
            <AccordionItem
              title={<h3>Do I have to install Rebrandly on my website?</h3>}
              text='No. Rebrandly is a web-based service requiring no software to install or code to write. After subscribing, you will be ready to start branding and shortening links right away. No tech skills are required!'
            />
            <AccordionItem
              title={<h3>How do I know your service is reliable and scalable?</h3>}
              text='Rebrandly is the industry-leading solution for millions of users who want a powerful way to brand and shorten links. The Rebrandly platform is hosted on Amazon Web Services in multiple locations with guaranteed 99.9% uptime and public uptime reports available 24/7.'
            />
            <AccordionItem
              title={<h3>What is your privacy and data protection policy?</h3>}
              text={<span>We take your data privacy and security seriously and implement stringent measures to protect your links and information. Rebrandly is the only SOC2 Type 2 certified platform for branded and shortened URLs; we are also GDPR compliant. For full details on our privacy and security policies, please visit <A size='lg' href='https://Rebrandly.com/Privacy-Policy' target='_blank'>Rebrandly.com/Privacy-Policy</A>.</span>}
            />
            <AccordionItem
              title={<h3>Can I create a custom plan with other features/options?</h3>}
              text={<span>Rebrandly has many out-of-the-box branded link shortener options, but if you have specific needs that differ from the plans you see here, we&apos;d love to chat about how we can help. <A href='https://www.rebrandly.com/get-in-touch' target='_blank'>Get in touch today!</A></span>}
            />
            <AccordionItem
              title={<h3>Do you have a special plan for Enterprise customers?</h3>}
              text={<span>At Rebrandly, we understand that Enterprise businesses often have more complex workflows and needs. We work with many global customers and have a dedicated team to help you identify the right plan and get up and running. If you need to discuss a solution custom-designed for your Enterprise, please <A href='https://www.rebrandly.com/get-in-touch' target='_blank'>get in touch</A>.</span>}
            />
            <AccordionItem
              title={<h3>I have more questions about Rebrandly, how can I contact you?</h3>}
              text={<span>We&apos;d love to connect! Let’s discuss how Rebrandly can help brand and shorten your links so they perform as needed. Please get in touch with us here! <A href='https://www.rebrandly.com/get-in-touch' target='_blank'>get in touch with us here</A>.</span>}
            />
          </Accordion>
        </div>

        <div className='flex start-xs display-none display-sm-flex'>
          <Accordion>
            <AccordionItem
              title={<h3>What is a URL shortener?</h3>}
              text={<span>
                A URL shortener, or a link shortener, simplifies long and complicated URLs into brief, comprehensible links. The application of a URL shortener can notably elevate your digital marketing effortss. When you choose to shorten URL, you&apos;re making a complex web address more user-friendly and accessible.
                <br />
                For instance, a URL such as <A size='lg' href='https://rb.gy/BakingMasterclass'>rb.gy/BakingMasterclass</A> immediately communicates the content of the link, making it more inviting for users to click.
                <br />
                For anyone producing or circulating content online, understanding how to shorten a URL is essential.
                <br />
                Enhance your online presence with our effortless and free URL shortening tool presented above.
              </span>}
            />
            <AccordionItem
              title={<h3>What is a custom URL shortener?</h3>}
              text='A custom URL shortener, like Rebrandly, facilitates the transformation of long URLs into succinct, brand-enhancing links. Rather than a random set of letters and numbers, a custom URL shortener allows you to include identifiable words or phrases. This tool enables you to convert a standard URL into a brand-relevant, shortened URL, thereby enhancing brand visibility and recall and building trust with your audience.'
            />
            <AccordionItem
              title={<h3>How do I shorten a URL for free?</h3>}
              text='You can shorten a URL for free using online services like RB.GY. The process couldn&apos;t be simpler: access RB.GY&apos;s URL shortener tool, insert your long URL into the specified field, then click to generate a shortened URL.'
            />
            <AccordionItem
              title={<h3>Does Rebrandly offers free plans?</h3>}
              text='Yes. Rebrandly offers free plans, as well as paid and custom options that vary in price based on the number of links you need to share, the number of users associated with your account, how many custom domains you use and which features are the most important to you.'
            />
            <AccordionItem
              title={<h3>Can I use a domain I already own?</h3>}
              text='Absolutely! Personalization, flexibility, and control are what Rebrandly is all about. With each of our paid plans, you can register or connect at least one top-level domain (your-domain.com) or subdomains (sub.your-domain.com) that you already own to create your branded short links using Rebrandly.'
            />
            <AccordionItem
              title={<h3>Do I have to install Rebrandly on my website?</h3>}
              text='No. Rebrandly is a web-based service requiring no software to install or code to write. After subscribing, you will be ready to start branding and shortening links right away. No tech skills are required!'
            />
            <AccordionItem
              title={<h3>How do I know your service is reliable and scalable?</h3>}
              text='Rebrandly is the industry-leading solution for millions of users who want a powerful way to brand and shorten links. The Rebrandly platform is hosted on Amazon Web Services in multiple locations with guaranteed 99.9% uptime and public uptime reports available 24/7.'
            />
          </Accordion>
          <Accordion>
            <AccordionItem
              title={<h3>How do I change a long URL to a short URL?</h3>}
              text='To shorten a URL, you&apos;ll need a service like RB.GY. First, copy the long URL you want to shorten. Then, access the URL shortener tool. Paste your long URL into the appropriate field, then click "Shorten URL." RB.GY will instantly generate a shortened version of your original URL, which leads to the same page when clicked.'
            />
            <AccordionItem
              title={<h3>Which link shortener is best?</h3>}
              text={<span>Rebrandly stands out as a top-rated URL shortener due to its dependability, security, and array of link management features. Beyond merely providing a way to shorten URL, Rebrandly also delivers performance tracking through click-through rates and the option to brand URLs. Explore Rebrandly&apos;s premium features to fully optimize your link management.</span>}
            />
            <AccordionItem
              title={<h3>What are the benefits of a short URL?</h3>}
              text={<span>
                When you choose to shorten a URL, you&apos;re optimizing your digital content in several ways. Short URLs are more manageable and tidy, fitting neatly into character-limited spaces like tweets or Instagram bios. They are also simpler to remember and type manually. Moreover, many URL shorteners include tracking capabilities, offering vital data on link engagement – a crucial tool for businesses and marketing campaigns. Finally, short URLs can be customized for increased brand recognition and user trust.
                    </span>}
            />
            <AccordionItem
              title={<h3>What is your privacy and data protection policy?</h3>}
              text={<span>We take your data privacy and security seriously and implement stringent measures to protect your links and information. Rebrandly is the only SOC2 Type 2 certified platform for branded and shortened URLs; we are also GDPR compliant. For full details on our privacy and security policies, please visit <A size='lg' href='https://Rebrandly.com/Privacy-Policy' target='_blank'>Rebrandly.com/Privacy-Policy</A>.</span>}
            />
            <AccordionItem
              title={<h3>Can I create a custom plan with other features/options?</h3>}
              text={<span>Rebrandly has many out-of-the-box branded link shortener options, but if you have specific needs that differ from the plans you see here, we&apos;d love to chat about how we can help. <A href='https://www.rebrandly.com/get-in-touch' target='_blank'>Get in touch today!</A></span>}
            />
            <AccordionItem
              title={<h3>Do you have a special plan for Enterprise customers?</h3>}
              text={<span>At Rebrandly, we understand that Enterprise businesses often have more complex workflows and needs. We work with many global customers and have a dedicated team to help you identify the right plan and get up and running. If you need to discuss a solution custom-designed for your Enterprise, please <A href='https://www.rebrandly.com/get-in-touch' target='_blank'>get in touch</A>.</span>}
            />
            <AccordionItem
              title={<h3>I have more questions about Rebrandly, how can I contact you?</h3>}
              text={<span>We&apos;d love to connect! Let’s discuss how Rebrandly can help brand and shorten your links so they perform as needed. Please get in touch with us here! <A href='https://www.rebrandly.com/get-in-touch' target='_blank'>get in touch with us here</A>.</span>}
            />
          </Accordion>
        </div>
      </div>
    </div>
  )
}

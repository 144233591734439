import React from 'react'
import { Button, Text, A } from '@rebrandly/styleguide'

import './DomainUpsellBanner.css'

function DomainUpsellBanner() {
    return (<div className='DomainUpsellBanner Container--wide'>
        <img className='DomainUpsellBanner__Img' src="./images/bitly.svg" alt="Rebrandly Domains" width={35} height={65}/>
        <div className='DomainUpsellBanner__Text'>
            <Text size='x-large'> Branded domains for just $2/year </Text>
            <Text size='regular' className='m-t-8'> Register a new custom domain and start creating branded links. Increase click-through-rate up to 39%. </Text>
        </div>
        <A href='https://rebrandly.com/get-domain' target='_blank'>
            <Button className='DomainUpsellBanner__CTA' label='Search Domain' size='lg'/>
        </A>
    </div>)
}

export default DomainUpsellBanner
import React from 'react'
import { A, CrossFadeLogos} from '@rebrandly/styleguide'

import './TestimonialsAndProof.css'

const TestimonialsAndProof = () => {
  return (
    <div className='TestimonialsAndProof home testimonials'>
        <div className='container hero'>
            <div className='div_vertival_flow md-mb-0 px-0'>

                <CrossFadeLogos columnsNumber={6} shuffle />

                <div className='div_horizontal_flow div_horizontal_flow_bottom_space w-hidden-tiny w-hidden-small'>
                    <div className='testimonial-home'>
                    <h2 className='new_h1_dark w-medium-center'>
                        <strong className='new_h2_dark'>
                        Don’t just take our word for it
                        </strong>
                    </h2>
                    <div className='testimonial-home-row'>
                        <div className='testimonial-home-item'>
                        <A
                            target='_blank'
                            href='https://www.trustpilot.com/review/www.rebrandly.com'
                            rel='noopener noreferrer'
                        >
                            <div className='TestimonialsAndProof__Starts testimonial-home-stars'>
                            <img src='./images/star.svg' alt='star' />
                            <img src='./images/star.svg' alt='star' />
                            <img src='./images/star.svg' alt='star' />
                            <img src='./images/star.svg' alt='star' />
                            <img src='./images/star.svg' alt='star' />
                            </div>
                            <div className='testimonial-home-desc'>
                            <h2>5/5</h2> TrustPilot
                            </div>
                        </A>
                        </div>
                        <div className='testimonial-home-item'>
                        <A
                            target='_blank'
                            href='https://www.g2.com/products/rebrandly/reviews'
                            rel='noopener noreferrer'
                        >
                            <div className='TestimonialsAndProof__Starts testimonial-home-stars'>
                            <img src='./images/star.svg' alt='star' />
                            <img src='./images/star.svg' alt='star' />
                            <img src='./images/star.svg' alt='star' />
                            <img src='./images/star.svg' alt='star' />
                            <img src='./images/star-half.svg' alt='star' />
                            </div>
                            <div className='testimonial-home-desc'>
                            <h2>4.6/5</h2> G2Crowd
                            </div>
                        </A>
                        </div>
                        <div className='testimonial-home-item'>
                        <A
                            target='_blank'
                            href='https://www.capterra.com/p/166180/Rebrandly/'
                            rel='noopener noreferrer'
                        >
                            <div className='TestimonialsAndProof__Starts testimonial-home-stars'>
                            <img src='./images/star.svg' alt='star' />
                            <img src='./images/star.svg' alt='star' />
                            <img src='./images/star.svg' alt='star' />
                            <img src='./images/star.svg' alt='star' />
                            <img src='./images/star.svg' alt='star' />
                            </div>
                            <div className='testimonial-home-desc'>
                            <h2>5/5</h2> Capterra
                            </div>
                        </A>
                        </div>
                    </div>
                    </div>
                    <div className='md-mb-0 div_vertival_flow'>
                        <div className='TestimonialsAndProof__Proof w-layout-grid grid'>
                            <div
                            id='w-node-e874f00b314f-799a2811'
                            className='div_vertival_flow'
                            >
                            <h1 className='stat-title stat-title-home'>2 billion</h1>
                            <p className='TestimonialsAndProof__stat-desc stat-desc'>Clicks tracked each month</p>
                            </div>
                            <div
                            id='w-node-e874f00b3154-799a2811'
                            className='div_vertival_flow'
                            >
                            <h1 className='stat-title stat-title-home'>1 billion</h1>
                            <p className='TestimonialsAndProof__stat-desc stat-desc'>Branded links created and counting</p>
                            </div>
                            <div
                            id='w-node-e874f00b3159-799a2811'
                            className='div_vertival_flow div_vertical_stats'
                            >
                            <h1 className='stat-title stat-title-home'>1.3 million</h1>
                            <p className='TestimonialsAndProof__stat-desc stat-desc'>Happy customers</p>
                            </div>
                            <div
                            id='w-node-e874f00b315e-799a2811'
                            className='div_vertival_flow'
                            >
                            <h1 className='stat-title stat-title-home'>90,000</h1>
                            <p className='TestimonialsAndProof__stat-desc stat-desc'>Developers using our APIs</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TestimonialsAndProof

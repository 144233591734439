import React from 'react'
import { A, Button, SpotIllustration, Text } from '@rebrandly/styleguide'
import './HomeContactSalesBanner.css'

const HomeContactSalesBanner = () => {
  return (
    <div className='HomeContactSalesBanner'>
      <div>
        <div className='HomeContactSalesBanner__paragraph container--half-left'>
          <h2 className='new_h2_dark-_left no-margin'>
            <strong className='new_h2_dark'>A fast and simple URL shortener</strong>
          </h2>
          <br />
          <p className='paragraph_dark_left'>
            <b>Free URL Shortener</b> for transforming long, ugly
            links into nice, memorable and trackable short URLs. Use it to shorten
            links for any social media platforms, blogs, SMS, emails, ads, or pretty
            much anywhere else you want to share them. Twitter, Facebook, YouTube, Instagram, WhatsApp, emails, SMS, videos. RB.GY is <b>the best free alternative to generic URL shorteners like bitly
            and tinyurl</b>.
            After shorterning the URL, <a href='/stats'>check how many clicks it received</a>.
          </p>
        </div>
      </div>

      <div>
        <div className='HomeContactSalesBanner__contact container--half-right full-height'>
          <SpotIllustration className='HomeContactSalesBanner__contact--ill' name='ill-spot-contact' size='sm' />
          <div>
            <Text className='Text--Detail m-b-16' size='large'>
              Experience the <strong> benefits of link management </strong> for your business
            </Text>
            
            <div className='HomeContactSalesBanner__CTAs'>
              <A href='https://rebrandly.sale/premium' target='_blank'>
                <Button label='Try Rebrandly' theme='primary' size='lg'/>
              </A>
              <A href='https://rebrandly.com/book-a-demo' target='_blank'>
                <Button label='Talk to Sales' size='lg' className='HomeContactSalesBanner__CTAs__Secondary'/>
              </A>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeContactSalesBanner